import { styled } from "~/ui/style/stitches.config";
import { StyledProfessionalDashboard } from "./Availability";
import { TimezoneMenu } from "~/ui/menus/TimezoneMenu";
import { AuthContext } from "~/utils/useAuth";
import { UserProfile, getUser } from "~/api/userApi";
import { useEffect, useState, useContext } from "react";
import { Button } from "~/ui/components/Button";
import { getSessionsByAuth } from "~/api/sessionApi";
import { Session, SessionStatus } from "~/types/sessions/types";
import { SimpleLoadingIndicator } from "../loading/LoadingPage";

export const SessionSection = ({ session }: { session: Session }) => {
  const [participantData, setParticipantData] = useState<UserProfile[]>([]);
  const [hostData, setHostData] = useState<UserProfile>();
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const participants = await Promise.all(
        session.invitees.map(async (id) => {
          const data = await getUser(id);
          return data;
        })
      );
      setParticipantData(
        participants.filter((p) => p.uuid !== session.ownerId)
      );
      const host = await getUser(session.ownerId);
      setHostData(host);
      setIsLoading(false);
    })();
  }, [session.invitees, session.ownerId]);

  return (
    <StyledSessionSection
      className="page-section"
      key={session.id}
      onMouseMove={(e) => {
        // set x coordinate to offset from div
        //TODO: do this in a better way encapsulated in the bigbutton component
        //@ts-ignore
        if (!document.getElementById("cursor-dot-" + session.id)) return;
        document.getElementById("cursor-dot-" + session.id)!.style.left =
          e.clientX + "px";
        // document.getElementById("cursor-dot")!.style.left = e.clientX + "px";
        document.getElementById("cursor-dot-" + session.id)!.style.top =
          e.clientY + "px";
      }}
    >
      {isLoading ? (
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "135px",
            top: "5px",
            opacity: 0.5,
          }}
        >
          <SimpleLoadingIndicator />
        </div>
      ) : (
        <>
          <div className="left-block">
            {hostData && (
              <img
                className="feature-photo"
                src={`images/profile-pics/${hostData.profilePic}`}
              />
            )}

            <div className="info-container">
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  opacity: 1,
                  // clipPath: "inset(0 0 0 0)",
                  display: "inherit",
                  justifyContent: "inherit",
                  alignItems: "inherit",
                  flexDirection: "inherit",
                }}
              >
                <div className="session-date">
                  <div className="month">
                    {session.sessionTime.month.substring(0, 3)}
                  </div>
                  <div className="day">
                    {session.sessionTime.yyyyMMdd.substring(6)}
                  </div>
                  <div className="weekday">
                    {session.sessionTime.weekDay.substring(0, 3)}
                  </div>
                </div>
                <div className="session-info-container">
                  <div className="session-title">{session.title}</div>

                  <div className="time-row">
                    <div className="session-time">{`${session.sessionTime.startTime.toLowerCase()} - ${session.sessionTime.endTime.toLowerCase()}`}</div>
                  </div>
                  {hostData && participantData.length === 1 && (
                    <div className="host-info">
                      {hostData.uuid === user?.uuid ? (
                        <>
                          You're hosting{" "}
                          <a
                            href={`profile/${participantData[0].username}`}
                            target="_blank"
                          >
                            {participantData[0].name}
                          </a>
                          .
                        </>
                      ) : (
                        <>
                          <a
                            href={`profile/${hostData.username}`}
                            target="_blank"
                          >
                            {hostData.name}
                          </a>{" "}
                          is hosting you.
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="button-container">
            <Button
              style={{}}
              bookingButton
              onClick={() => {
                window.location.href = `/session/${session.id}`;
              }}
            >
              View details
            </Button>
            <Button
              className="purple"
              bookingButton
              onClick={() => {
                window.open(
                  window.location.protocol + "/room/" + session.roomName,
                  "_blank"
                );
              }}
            >
              Join room
            </Button>
          </div>
        </>
      )}
    </StyledSessionSection>
  );
};

const StyledSessionSection = styled("div", {
  display: "flex",
  alignItems: "center",
  position: "relative",
  justifyContent: "space-between",
  height: "135px !important",
  border: "1px solid rgba(0, 0, 0, 0.4) !important",
  boxShadow: "none !important",
  marginBottom: "15px !important",
  "& .left-block": {
    display: "flex",
    alignItems: "center",
  },
  "& .cursor-dot.smaller-size": {
    transform: "scale(0.5)",
  },
  "& .session-date": {
    position: "absolute",
    top: 0,
    bottom: 0,
    margin: "auto",
    left: "-50px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& .month": {
      fontFamily: "KeplerLightCondensed",
      fontSize: "20px",
      textTransform: "uppercase",
    },
    "& .day": {
      fontSize: "24px",
      marginTop: "-6px",
    },
    "& .weekday": {
      fontSize: "12px",
      textTransform: "uppercase",
    },
  },

  "& .feature-photo": {
    width: "58px",
    height: "100px",
    objectFit: "cover",
    borderRadius: "45px",
    border: "1px solid black",
    objectPosition: "center",
    marginRight: "18px",
  },

  "& .session-info-container": {
    marginBottom: "4px",
    "& .session-title": {
      fontSize: "24px",
      fontFamily: "KeplerLightCondensed",
    },

    "& .time-row": {
      fontSize: "16px",
      display: "flex",
      alignItems: "center",
      marginBottom: "6px",
      "& .session-time": {
        marginRight: "8px",
      },
    },
    "& .host-info": {
      fontSize: "12px",
      marginTop: "4px",
      "& a": {
        textDecoration: "none",
        fontWeight: "500",
        transition: "all 0.2s",
        "&:hover": {
          opacity: 0.6,
        },
      },
    },
  },
  "& .button-container": {
    display: "flex",
    flexDirection: "column",
    minWidth: "110px",
    marginLeft: "8px",
    "& button": {
      fontSize: "12px",
      padding: "10px 16px",
      borderRadius: "11px",
      backgroundColor: "white",
      boxShadow: "0px 3px 0 0 black",
      "&.purple": {
        backgroundColor: "$lightPurple",
        marginTop: "10px",
      },
      "&:hover": {
        boxShadow: "0px 0 0 0 black",
        transform: "translateY(3px)",
      },
    },
  },
  "@mobile": {
    // width: "calc(100% - 50px) !important",
    width: "100% !important",
    paddingLeft: "70px !important",
    height: "auto !important",
    flexDirection: "column",
    "& .session-date": {
      left: "18px",
    },
    "& .info-container": {
      textAlign: "left",
    },
    "& .button-container": {
      flexDirection: "row",
      marginTop: "10px",
      width: "calc(100% + 8px)",
      "& button": {
        marginTop: "0 !important",
        marginLeft: "3px",
        marginRight: "3px",
        width: "50%",
      },
      // height: "10px",
    },
  },
});

export const NoSessionsSection = ({
  isApprovedForServiceListings,
}: {
  isApprovedForServiceListings?: boolean;
}) => {
  const { user } = useContext(AuthContext);

  return (
    <StyledNoSessionsSection className="page-section">
      You have no confirmed sessions yet.{" "}
      {isApprovedForServiceListings && (
        <>
          <br />
          You’ll receive an email when someone requests a session.
        </>
      )}
      <Button
        bookingButton
        purpleButton
        style={{
          padding: "10px 26px",
          marginTop: "16px",
          fontWeight: "normal",
          borderRadius: "11px",
        }}
        onClick={() => {
          isApprovedForServiceListings
            ? (window.location.href = "/profile/" + user?.username)
            : (window.location.href = "/pros");
        }}
      >
        {isApprovedForServiceListings ? "View my profile" : "Find a reader"}
      </Button>
    </StyledNoSessionsSection>
  );
};

const StyledNoSessionsSection = styled(StyledSessionSection, {
  display: "flex",
  flexDirection: "column",
  marginTop: "60px",
  fontSize: "12px",
  lineHeight: "1.3em",
  padding: "30px !important",
  textAlign: "center",
  "@mobile": {
    width: "100% !important",
    marginLeft: "0 !important",
  },
});

export const Upcoming = () => {
  const { user } = useContext(AuthContext);
  const [timezone, setTimezone] = useState<string>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [data, setData] = useState<Session[]>();
  const [isLoadingAllSessions, setIsLoadingAllSessions] = useState(true);
  const [isServicePro, setIsServicePro] = useState(false);
  useEffect(() => {
    (async () => {
      if (!user) return;
      const u = await getUser(user?.uuid);
      setIsServicePro(u.proDetails?.approvedForServiceListing ? true : false);
      const s = await getSessionsByAuth(timezone);
      setData(
        s.filter((session) => session.status === SessionStatus.CONFIRMED)
      );
      setIsLoadingAllSessions(false);
    })();
  }, [user?.uuid, timezone]);
  return (
    <StyledUpcomingPage
      onClick={(e) => {
        if (!(e.target as HTMLElement).classList.contains("menu")) {
          document.querySelectorAll(".menu")?.forEach((menu) => {
            menu.classList.remove("open");
          });
        }
      }}
    >
      <div className="column"></div>
      <div className="column middle">
        <div className="page-title">Your sessions</div>
        <div className="page-subtitle">View your upcoming tarot bookings.</div>
        <h3 className="sub-subtitle" style={{ textTransform: "initial" }}>
          Video & audio included. Desktop recommended for the full experience.
        </h3>
        {isLoadingAllSessions ? (
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100px",
              top: "100px",
              opacity: "0.5",
            }}
          >
            <SimpleLoadingIndicator />
          </div>
        ) : (
          <>
            {data && data.length === 0 && (
              <NoSessionsSection isApprovedForServiceListings={isServicePro} />
            )}
            {data && data.length > 0 && (
              <TimezoneMenu
                timezone={timezone}
                setTimezone={setTimezone}
                style={{
                  backgroundColor: "white",
                  marginTop: "48px",
                  marginBottom: 0,
                  paddingTop: "8px",
                  paddingBottom: "8px",
                }}
              />
            )}
            <div className="session-container">
              {data &&
                data.map((session) => (
                  <div key={session.id + "-session"}>
                    <SessionSection key={session.id} session={session} />
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
      <div className="column"></div>
    </StyledUpcomingPage>
  );
};

export const StyledUpcomingPage = styled(StyledProfessionalDashboard, {
  marginTop: "40px",
  "& .page-subtitle": {
    fontSize: "24px",
    fontWeight: "400",
    marginTop: "10px",
    fontFamily: "KeplerLightCondensed",
    "@mobile": {
      fontSize: "26px",
      lineHeight: "25px",
    },
  },
  "& .sub-subtitle": {
    fontSize: "12px",
    fontWeight: "normal",
    textTransform: "uppercase",
    marginTop: "14px",
  },
  "& .session-container": {
    width: "100%",
    marginTop: "24px",
    paddingBottom: "160px",
  },
  "@mobile": {
    marginTop: "-20px",
    "& .session-container": {
      marginTop: "40px",
    },
    "& #tz-menu": {
      marginTop: "24px !important",
    },
    "& .sub-subtitle": {
      textAlign: "center",
    },
  },
});
