import { motion } from "framer-motion";
import { useState } from "react";
import { keyframes, styled } from "../style/stitches.config";
import { useLocation } from "wouter";

export const BookingBigButton = ({}: {}) => {
  const [location, setLocation] = useLocation();

  return (
    <StyledBigButton
      booking
      onClick={() => {
        // set on booking page
        setLocation("/book");
      }}
    >
      <div className="big-button-underneath" />
      <div className="big-button-shadow" />
      <div id="container">
        <div id="circle">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="300px"
            height="300px"
            viewBox="0 0 300 300"
            enableBackground="new 0 0 300 300"
            xmlSpace="preserve"
          >
            <defs>
              <path
                id="circlePath"
                d="M 150, 150 m -61, 14 a 50,50 0 0,1 150,0 a 60,60 0 0,1 -150,0 "
              />
            </defs>
            <circle cx="164" cy="164" r="100" fill="none" />
            <g>
              <use xlinkHref="#circlePath" fill="none" />
              <text fill="#000">
                <textPath xlinkHref="#circlePath">{"BOOK A SESH"}</textPath>
              </text>
            </g>
          </svg>
        </div>
      </div>

      <div className="big-button"></div>
      <div className="big-button-top">
        <video autoPlay loop muted>
          <source src="/images/moonlight-landing-book.mp4" type="video/mp4" />
        </video>
      </div>
    </StyledBigButton>
  );
};

export const BigButton = ({
  cursorClasses,
  onClick,
  enabled,
  text,
  style,
  insideImg,
  insideDiv,
  cursorIdSuffix,
}: {
  cursorClasses: string;
  onClick: Function;
  enabled: boolean;
  text?: string;
  style?: Object;
  insideImg?: string;
  insideDiv?: JSX.Element;
  cursorIdSuffix?: string;
}) => {
  const [isHoveringButton, setIsHoveringButton] = useState(false);
  const dotId = `cursor-dot${cursorIdSuffix ? "-" + cursorIdSuffix : ""}`;
  return (
    <>
      <StyledCursorDot
        id={dotId}
        className={`cursor-dot ${cursorClasses} hovering-${isHoveringButton}`}
        // className={`transitioning-${isTransitioningToRoom} hovering-${isHoveringButton}`}
      ></StyledCursorDot>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        key="transition-wash"
        className="transition-wash"
      ></motion.div>

      <div
        className="button"
        onMouseEnter={() =>
          (document.getElementById(dotId)!.style.opacity = "1")
        }
        onMouseLeave={() =>
          (document.getElementById(dotId)!.style.opacity = "0")
        }
      >
        <StyledBigButton
          style={style}
          isHovering={isHoveringButton}
          onClick={() => {
            if (enabled) onClick();
          }}
          onMouseEnter={() => {
            if (enabled) setIsHoveringButton(true);
          }}
          onMouseLeave={() => {
            if (enabled) setIsHoveringButton(false);
          }}
        >
          <div className="big-button-underneath" />
          <div className="big-button-shadow" />

          <div id="container">
            <div id="circle">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="300px"
                height="300px"
                viewBox="0 0 300 300"
                enableBackground="new 0 0 300 300"
                xmlSpace="preserve"
              >
                <defs>
                  <path
                    id="circlePath"
                    d="M 150, 150 m -61, 14 a 50,50 0 0,1 150,0 a 60,60 0 0,1 -150,0 "
                  />
                </defs>
                <circle cx="164" cy="164" r="100" fill="none" />
                <g>
                  <use xlinkHref="#circlePath" fill="none" />
                  <text fill="#000">
                    <textPath xlinkHref="#circlePath">{text}</textPath>
                  </text>
                </g>
              </svg>
            </div>
          </div>

          <div className="big-button"></div>
          <div className="big-button-top">
            {insideDiv ? (
              <div className="plus">{insideDiv}</div>
            ) : (
              <img
                className={`plus `}
                src={insideImg ? insideImg : "/images/start.svg"}
              ></img>
            )}
          </div>
        </StyledBigButton>
      </div>
    </>
  );
};

const StyledCursorDot = styled(motion.div, {
  "@mobile": {
    display: "none",
  },
  opacity: 0,
  "& .text": {
    marginTop: "72px",
    opacity: "0",
    transition: "all 0.3s",
    fontSize: "11px",
  },
  position: "fixed",
  top: "-1000px",
  left: "-1000px",
  // zIndex: "99999",
  borderRadius: "50%",
  width: "170px",
  height: "170px",
  marginLeft: "-85px",
  marginTop: "-85px",
  backgroundColor: "white",
  pointerEvents: "none",
  zIndex: "4",
  textAlign: "center",
  verticalAlign: "center",
  "&.transitioning-true": {
    transition: "all 0.8s  cubic-bezier(0.65, 0, 0.35, 1)",
    transform: "scale(100)",
    zIndex: "1000",
  },
  "&.hovering-true": {
    "& .text": {
      opacity: 1,
    },
  },
});

const rotate = keyframes({
  "0%": {
    transform: "rotate(0deg) ",
  },
  "100%": {
    transform: "rotate(360deg) ",
  },
});

const rotateReverse = keyframes({
  "0%": {
    transform: "rotate(0deg) ",
  },
  "100%": {
    transform: "rotate(-360deg) ",
  },
});

const StyledBigButton = styled(motion.div, {
  marginTop: "-40px",
  marginBottom: "-20px",

  "& #circle": {
    animation: `${rotate} 10s linear infinite`,
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    margin: "auto",
    fontSize: "10px",
    zIndex: "1",
    pointerEvents: "none",
    fontWeight: "500",
    width: "328px",
    height: "328px",
    transformOrigin: "center",
    marginLeft: "-24px",
    "@mobile": {
      fontSize: "13px",
    },
  },
  position: "relative",
  width: "280px",
  height: "280px",

  "& .transition-wash": {
    backgroundColor: "blue",
    width: "200px",
    height: "200px",
    position: "fixed",
    top: 0,
    left: 0,
  },

  "& .big-button, & .big-button-underneath, & .big-button-top, & .big-button-shadow":
    {
      width: "144px",
      height: "144px",
      marginTop: "24px",
      borderRadius: "50%",
      transition: "all 0.3s  ease-out",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      margin: "auto",
      position: "absolute",
      backgroundColor: "black",

      zIndex: "3",
      "&.big-button-top": {
        backgroundColor: "transparent",
        mixBlendMode: "difference",

        zIndex: "5",
      },
      "& .plus": {
        width: "20px",
        height: "20px",
        position: "absolute",
        left: "2px",
        right: 0,
        top: "-5px",
        bottom: 0,
        margin: "auto",
        "&.inverted-true": {
          filter: "invert(1)",
          width: "40px",
          height: "40px",
        },
      },
    },

  "& .big-button-underneath": {
    backgroundColor: "white",
    zIndex: "1",
  },
  "& .big-button-shadow": {
    // boxShadow: "0px 0px 40px #4d7709",
    boxShadow: "0px 0px 40px #B288F6",
  },
  variants: {
    isHovering: {
      true: {
        cursor: "pointer",

        "& .big-button, & .big-button-underneath": {
          width: "170px",
          height: "170px",
        },
      },
    },
    booking: {
      true: {
        transition: "transform 0.2s",
        "&:hover": {
          cursor: "pointer",
          transform: "scale(1.06)",
        },

        "& .big-button-top": {
          backgroundColor: "transparent",
          mixBlendMode: "unset",

          zIndex: "5",
        },
        "& video": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "50%",
          border: "1px solid black",
        },
        "& #circle": {
          animation: `${rotateReverse} 10s linear infinite`,
        },
      },
    },
  },
});
