import { useState } from "react";
import {
  ReadableTimesToApiTimes,
  apiTimeToReadableTime,
  readableTimeToApiTime,
} from "~/utils/datetime";
import { styled } from "../style/stitches.config";

const getAllPricesBetween = (min: number, max: number) => {
  const incr = 5;
  const allPrices: number[] = [];
  for (let i = min; i <= max; i += incr) {
    allPrices.push(i);
  }
  return allPrices;
};

export const TimeBubble = ({
  time,
  setTime,
  timeMin,
  timeMax,
}: {
  time: string;
  setTime: (newTime: string) => void;
  timeMin?: string;
  timeMax?: string;
}) => {
  const isTimeValid = (t: string) => {
    const isTimeInMax = timeMax ? parseInt(t) < parseInt(timeMax) : true;
    const isTimeInMin = timeMin ? parseInt(t) > parseInt(timeMin) : true;
    return isTimeInMax && isTimeInMin;
  };

  return (
    <StyledTimeBubble
      className={`menu`}
      onClick={(e) => {
        const sectionHeight = 22;
        const indexOfCurrentTime = Object.keys(ReadableTimesToApiTimes).indexOf(
          apiTimeToReadableTime(time)
        );

        (e.target as HTMLElement).classList.toggle("open");
        (e.target as HTMLElement).children[0].scrollTop =
          indexOfCurrentTime * sectionHeight - 0.5 * sectionHeight;
      }}
    >
      <div className={`inner-menu`}>
        {Object.keys(ReadableTimesToApiTimes).map((tKey) => {
          const t = ReadableTimesToApiTimes[tKey];
          return (
            <div
              key={"time-bubble-" + t}
              className={`menu-item valid-${isTimeValid(t)} selected-${
                t === time
              }`}
              onClick={(e) => {
                e.stopPropagation();
                (
                  e.target as HTMLElement
                ).parentElement!.parentElement!.classList.remove("open");
                if (isTimeValid(t)) {
                  setTime(t);
                }
              }}
            >
              {apiTimeToReadableTime(t)}
            </div>
          );
        })}
      </div>
      {apiTimeToReadableTime(time)}
    </StyledTimeBubble>
  );
};

const StyledTimeBubble = styled("div", {
  fontSize: "12px",
  border: "1px solid $gray400",
  borderRadius: "13px",
  padding: "2px 6px",
  cursor: "pointer",
  position: "relative",
  width: "70px",
  textAlign: "center",
  backgroundColor: "white",
  "& .inner-menu": {
    overflow: "overlay",

    display: "none",
    position: "absolute",
    zIndex: "10",
    maxHeight: "200px",
    // overflow: "scroll",
    backgroundColor: "white",
    border: "1px solid rgba(0,0,0,0.3)",
    left: "-2px",
    top: "-2px",
    textAlign: "left",
    borderRadius: "11px",
    "& .menu-item": {
      padding: "4px 8px",
      textWrap: "nowrap",
      "&:hover": {
        backgroundColor: "$gray200",
      },
      "&.selected-true": {
        backgroundColor: "$gray300",
      },
      "&.valid-false": {
        opacity: 0.3,
        pointerEvents: "none",
        // display: "none",
        "&:hover": {
          backgroundColor: "white",
        },
        // opacity: "0.3",
      },
    },
  },

  "&.open .inner-menu": {
    display: "block",
  },
});

export const PriceBubble = ({
  price,
  maxPrice,
  minPrice,
  setUpdatedPrice,
}: {
  price: number;
  maxPrice: number;
  minPrice: number;
  setUpdatedPrice?: Function;
}) => {
  const allPricesBetween = getAllPricesBetween(minPrice, maxPrice);
  return (
    <StyledTimeBubble
      style={{ marginTop: "14px" }}
      className={`menu`}
      onClick={(e) => {
        (e.target as HTMLElement).classList.toggle("open");

        const sectionHeight = 22;
        const indexOfCurrentPrice = allPricesBetween.indexOf(price);

        (e.target as HTMLElement).children[0].scrollTop =
          indexOfCurrentPrice * sectionHeight - 0.5 * sectionHeight;
      }}
    >
      <div
        className="inner-menu"
        style={{
          width: "72px",
          textAlign: "center",
        }}
      >
        {allPricesBetween.map((p) => {
          return (
            <div
              key={"all-prices-" + p}
              className={`menu-item valid-true selected-${p === price}`}
              onClick={(e) => {
                e.stopPropagation();
                (
                  e.target as HTMLElement
                ).parentElement!.parentElement!.classList.remove("open");
                setUpdatedPrice && setUpdatedPrice(p);
              }}
            >
              ${p}
            </div>
          );
        })}
      </div>
      ${price}
    </StyledTimeBubble>
  );
};
