import { localState } from "~/state/state";
import { sendAnalyticsEvent } from "~/utils/analytics";
import axios from "./axiosConfig.js";
import { RoomEvent } from "~/types/room/types.js";

export interface Room {
  name : string
  url : string
  isActive : boolean
  createdAt : Date
  owner: string
  numParticipants?: number
}

interface UpdateRoomRequest {
  roomName: string
  numParticipants: number
}


const ROOM_ENDPOINT = "rooms/"


const updateRoom = async (roomName : string, numParticipants: number) => {
  const updateRoomData : UpdateRoomRequest = {
   roomName,
   numParticipants
  }
  const response = await axios.put(ROOM_ENDPOINT, updateRoomData)
  if (response.status !== 200) {
    throw new Error(response.statusText)
  }
}
const handleJoinRoom = (roomName: string) => {
  if (roomName) {
    sendAnalyticsEvent("joined_room", {
      room_name: roomName
    });
   
    window.location.pathname = "/room/" + roomName;
  }
};

export const handleCreateRoom = () => {
  localState.isConnecting = true;
  createRoom().then((res) => {
    handleJoinRoom(res.name);
  }).catch((_error) => {
    window.location.pathname = "/signup";
  });

}

const createRoom = async () : Promise<Room> => {
  const response = await axios.post(ROOM_ENDPOINT)
  if (response.status === 200) {
    sendAnalyticsEvent("created_room", {
      room_name: response.data.name
    });
    return response.data as Room
  } else {
    throw new Error(response.statusText)
  }
}

const getRoom = async (roomName : string) : Promise<Room | null> => {
  try {
    const response = await axios.get(ROOM_ENDPOINT + roomName)
    return response.data as Room
  } catch {
    return null
  }
}

const getMyRooms = async () : Promise<Room[]> => {
  const response = await axios.get(ROOM_ENDPOINT)
  if (response.status === 200) {
    return response.data as Room[]
  } else {
    throw new Error(response.statusText)
  }
}

const deleteRoom = async (roomName : string) => {
  const response = await axios.delete(ROOM_ENDPOINT + roomName)
  if (response.status === 200) {
    sendAnalyticsEvent("deleted_room", {
      room_name: roomName
    });
    return
  } else {
    throw new Error(response.statusText)
  }
}

export { updateRoom, createRoom, getRoom, getMyRooms, deleteRoom }