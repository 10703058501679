import { styled } from "~/ui/style/stitches.config";

export const MenuTooltip = ({
  display,
  text,
  vertical,
  question,
  menuStyle,
  leftOnMobile,
  tooltipStyle,
}: {
  display?: JSX.Element;
  text: string;
  vertical?: boolean;
  question?: boolean;
  menuStyle?: Object;
  tooltipStyle?: Object;
  leftOnMobile?: boolean;
}) => {
  return (
    <StyledTooltip
      vertical={vertical}
      question={question}
      leftOnMobile={leftOnMobile}
    >
      <div className="tooltip-menu" style={tooltipStyle}>
        <img className="tooltip-arrow" src={"/images/room/point.svg"} />
        <div
          style={menuStyle}
          className="tooltip-contents"
          dangerouslySetInnerHTML={{ __html: text }}
        ></div>
      </div>
      {display}
    </StyledTooltip>
  );
};

export const QuestionHover = ({
  text,
  menuStyle,
  vertical,
  leftOnMobile,
  tooltipStyle,
}: {
  text: string;
  menuStyle?: Object;
  vertical?: boolean;
  leftOnMobile?: boolean;
  tooltipStyle?: Object;
}) => {
  return (
    <MenuTooltip
      text={text}
      vertical={vertical}
      menuStyle={menuStyle}
      question
      leftOnMobile={leftOnMobile}
      tooltipStyle={tooltipStyle}
      display={<>?</>}
    />
  );
};

const StyledTooltip = styled("div", {
  marginLeft: "6px",
  position: "relative",
  cursor: "pointer",
  fontSize: "10px",

  "& .tooltip-menu": {
    opacity: 0,
    pointerEvents: "none",
    position: "absolute",
    left: "24px",
    width: "auto",
    textWrap: "nowrap",
    backgroundColor: "black",
    fontWeight: "normal",
    color: "white",
    padding: "12px 18px",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .tooltip-contents": {
      textAlign: "center",
    },
    "& img": {
      position: "absolute",
      transform: "rotate(180deg)",
      left: "-8px",
      width: "16px",
    },
  },

  "&:hover": {
    "& .tooltip-menu": {
      opacity: 1,
      pointerEvents: "all",
    },
  },
  variants: {
    question: {
      true: {
        width: "14px",
        height: "14px",
        fontWeight: "300",
        borderRadius: "8px",
        border: "0.5px solid black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&::before": {
          content: "''",
          position: "absolute",
          top: "-2px",
          left: "50%",
          width: "20px",
          height: "20px",
        },
        "& .inside-button": {
          marginTop: "8px",
          backgroundColor: "$gray700",
          borderRadius: "12px",
          padding: "5px 8px",
          textAlign: "center",
          color: "white !important",
          transition: "all 0.2s",
          "&:hover": {
            opacity: 0.8,
          },
        },
        "& a": {
          textDecoration: "none",
        },
      },
    },
    vertical: {
      true: {
        zIndex: "99",
        "& .tooltip-menu": {
          width: "130px",
          height: "fit-content !important",
          top: "24px",
          left: "-56px",
          alignItems: "flex-start",
          padding: "10px 14px",

          "& .tooltip-contents": {
            lineHeight: "14px",
            paddingTop: "4px",
            // overflow: "hidden",
            // WebkitBoxOrient: "vertical",
            // display: "-webkit-box",
            // WebkitLineClamp: 5,
            textWrap: "wrap",
            textOverflow: "ellipsis",
            // maxHeight: "110px",
          },
          "& img": {
            position: "absolute",
            transform: "rotate(-90deg)",
            left: "56px",
            top: "-8px",
            width: "12px !important",
            height: "12px !important",
          },
        },
      },
    },
    leftOnMobile: {
      true: {
        "@mobile": {
          "& .tooltip-menu": {
            left: "unset",
            right: "24px",
          },
          "& img.tooltip-arrow": {
            transform: "none",
            left: "unset",
            right: "-8px",
          },
        },
      },
    },
  },
});
