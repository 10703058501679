import { hoursToMilliseconds } from "date-fns";
import { useEffect, useState, useContext } from "react";
import { cancelSession, getSessionById } from "~/api/sessionApi";
import { Room, getRoom } from "~/api/roomApi";
import { UserProfile, getUser } from "~/api/userApi";
import { Session, SessionStatus } from "~/types/sessions/types";
import { Button } from "~/ui/components/Button";
import { CopyButton } from "~/ui/components/CopyButton";
import { QuestionHover } from "~/ui/components/Tooltip";
import { styled } from "~/ui/style/stitches.config";
import { AuthContext } from "~/utils/useAuth";
import { SimpleLoadingIndicator } from "../loading/LoadingPage";
import { MOONLIGHT_SESSION_FEE_PERCENTAGE } from "~/utils/consts";
import { dateNumFromyyyyMMdd } from "~/utils/datetime";
import { imageUrl } from "~/utils/imageurl";
import { trackEvent } from "~/api/analyticsApi";

export const SessionView = ({ id }: { id: string }) => {
  // todo: get session by session id

  const [sessionData, setSessionData] = useState<Session>();
  const [isHost, setIsHost] = useState(false);
  const [longDateString, setLongDateString] = useState("");
  const [roomLink, setRoomLink] = useState("");

  const [allUserData, setAllUserData] = useState<UserProfile[]>([]);
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const [emailCopied, setEmailCopied] = useState(false);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    trackEvent("/session_details/visited_page", {
      sessionId: id,
    });
    (async () => {
      const s = await getSessionById(
        id,
        Intl.DateTimeFormat().resolvedOptions().timeZone
      );
      setSessionData(s);
      setIsHost(s.ownerId === user?.uuid);
      const allUserIds = [
        s.ownerId,
        ...s.invitees.filter((i) => i !== s.ownerId),
      ];
      const allUsers = await Promise.all(allUserIds.map((id) => getUser(id)));
      setLongDateString(
        `${s.sessionTime.weekDay}, ${s.sessionTime.month} ${dateNumFromyyyyMMdd(
          s.sessionTime.yyyyMMdd
        )}, ${s.sessionTime.yyyyMMdd.substring(
          0,
          4
        )}, ${s.sessionTime.startTime.toLowerCase()} - ${s.sessionTime.endTime.toLowerCase()}`
      );
      setRoomLink(
        window.location.protocol +
          "//" +
          window.location.host +
          "/room/" +
          s?.roomName
      );
      setAllUserData(allUsers);
    })();
  }, [user?.uuid]);

  useEffect(() => {
    if (emailCopied) {
      setTimeout(() => {
        setEmailCopied(false);
      }, 5000);
    }
  }, [emailCopied]);

  const [isCanceling, setIsCanceling] = useState(false);

  return (
    <div
      style={{
        width: "100vw",
        height: "100%",
        overflow: "scroll",
      }}
    >
      <StyledSessionPage
        cancelled={
          sessionData &&
          (sessionData.status === SessionStatus.CANCELLED ||
            sessionData.status === SessionStatus.EXPIRED)
        }
      >
        <div className="session-box">
          {sessionData && (
            <>
              <div
                className="session-status"
                style={{
                  marginBottom:
                    sessionData.status !== SessionStatus.REQUESTED
                      ? "18px"
                      : "",
                }}
              >
                {sessionData.status === SessionStatus.REQUESTED
                  ? `Request sent! ${
                      allUserData.filter(
                        (user) => user.uuid !== sessionData.ownerId
                      )[0]?.email
                        ? `Details sent to ${
                            allUserData.filter(
                              (user) => user.uuid !== sessionData.ownerId
                            )[0]?.email
                          } 
                    `
                        : ``
                    } `
                  : sessionData.status === SessionStatus.CONFIRMED ||
                    sessionData.status === SessionStatus.COMPLETED
                  ? "Your session is confirmed! ✨"
                  : sessionData.status === SessionStatus.CANCELLED
                  ? "Your session is cancelled."
                  : sessionData.status === SessionStatus.EXPIRED
                  ? "This session request expired."
                  : ""}
              </div>
              {sessionData.status === SessionStatus.REQUESTED && (
                <div className="requested-info">
                  Waiting for the host to confirm. You'll receive a confirmation
                  email and a calendar invite if your session is accepted.
                </div>
              )}
              {sessionData.status === SessionStatus.EXPIRED && (
                <div className="requested-info">
                  Either 4 days passed or the requested session time already
                  happened.
                </div>
              )}
              <div className="icon-line">
                <img src="/images/moon.svg" />
                {`${sessionData.title} • $${sessionData.cost} USD`}
                {sessionData.status === SessionStatus.REQUESTED && (
                  <QuestionHover
                    vertical
                    menuStyle={{ padding: 0, textAlign: "left" }}
                    text={`<div style='text-align:center;'>Payment processed only after session is confirmed.</div>`}
                  ></QuestionHover>
                )}
                {(sessionData.status === SessionStatus.CONFIRMED ||
                  sessionData.status === SessionStatus.COMPLETED) && (
                  <QuestionHover
                    vertical
                    menuStyle={{ padding: 0, textAlign: "left" }}
                    text={
                      isHost
                        ? `
            Client pays
            <br />
            $${sessionData.cost} USD
            <br />
            <br />
            You receive
            <br />
            $${
              Math.round(
                100 * sessionData.cost * (1 - MOONLIGHT_SESSION_FEE_PERCENTAGE)
              ) / 100
            } USD
            <br />
            <br />
            Payment has been processed.
            <br/>
            <div class='inside-button' style='margin-top:10px;margin-bottom:6px;'><a href='https://help.moonlight.world/article/17-payouts-and-pricing' target='_blank'>Learn more</a></div>
              `
                        : "<div style='text-align:center;'>Payment has been processed.</div>"
                    }
                  ></QuestionHover>
                )}
              </div>
              <div className="icon-line">
                <img src="/images/more-menu/upcoming.svg" />
                {longDateString}
              </div>
              <div className="icon-line">
                <img src="/images/timezone.svg" />
                {`${sessionData.sessionTime.timeZone}`}
              </div>
              <div className="icon-line">
                <img src="/images/video.svg" />

                {sessionData.duration === 30 ? "30 min" : "1 hour"}
              </div>
              {(sessionData.status === SessionStatus.CONFIRMED ||
                sessionData.status === SessionStatus.COMPLETED) && (
                <div className="link-line">
                  <div className="icon-line">
                    <img src="/images/room/link-out.svg" />
                    <a
                      className="link-text"
                      href={roomLink}
                      target="_blank"
                    >{`${roomLink.split("https://").join("")}`}</a>
                  </div>
                  <>
                    <CopyButton
                      id="copy-room-link-button"
                      buttonText={"Copy link"}
                      copiedText={"Link copied!"}
                      copyContent={roomLink}
                    />
                  </>
                </div>
              )}
              <div className="guests-container">
                {allUserData.map((user) => (
                  <div
                    key={user.uuid + sessionData.id + isHost}
                    className="guest"
                  >
                    <div className="guest-line-container">
                      <div
                        className="guest-line"
                        onClick={() => {
                          // open profile in a new window
                          window.open(`/profile/${user.username}`, "_blank");
                        }}
                      >
                        <img
                          className="guest-pic"
                          src={imageUrl(`/profile-pics/${user.profilePic}`, 60)}
                        />
                        <div className="guest-name">{user.name}</div>
                        {user.uuid === sessionData.ownerId && (
                          <div className="host-badge">HOST</div>
                        )}
                      </div>
                      {
                        // display only if they're the host and the user is a client, or they're a client and the user is the host
                        sessionData.status !== SessionStatus.REQUESTED &&
                          ((isHost && user.uuid !== sessionData.ownerId) ||
                            (!isHost && user.uuid === sessionData.ownerId)) && (
                            <CopyButton
                              id="copy-email-button"
                              buttonText={"Copy email"}
                              copiedText={"Email copied!"}
                              copyContent={user.email}
                              style={{
                                marginRight: "-16px",
                                marginTop: "-6px",
                              }}
                            />
                          )
                      }
                    </div>
                    {sessionData.sessionUserContext.map((suc) => {
                      if (suc.userId === user.uuid) {
                        return (
                          <div className="session-context">
                            {`${suc.context}`}
                          </div>
                        );
                      }
                    })}
                  </div>
                ))}
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {sessionData.status === SessionStatus.REQUESTED && (
                  <Button
                    Theme={"dark"}
                    style={{ width: "200px" }}
                    onClick={() => {
                      window.location.href = "/";
                    }}
                  >
                    Return home
                  </Button>
                )}
                {(sessionData.status === SessionStatus.CONFIRMED ||
                  sessionData.status === SessionStatus.COMPLETED) && (
                  <>
                    <Button
                      mobileSz14
                      Theme={"dark"}
                      style={{ width: "200px" }}
                      onClick={() => {
                        window.location.href = "/upcoming";
                      }}
                    >
                      Return to upcoming
                    </Button>
                    <div className="cancel-description">
                      If you need to reschedule, contact each other.
                    </div>

                    {sessionData.status !== SessionStatus.COMPLETED && (
                      <>
                        <div className="cancel-tagline">
                          {isCancelOpen
                            ? "Are you sure you want to cancel?"
                            : "Need to cancel? 24-hour notice required."}
                        </div>
                        {isCancelOpen ? (
                          <div className="cancel-buttons">
                            <Button
                              lightweight
                              style={{ width: "100px", marginRight: "6px" }}
                              onClick={() => {
                                setIsCancelOpen(false);
                              }}
                            >
                              No
                            </Button>
                            <Button
                              lightweight
                              style={{ width: "100px" }}
                              onClick={() => {
                                setIsCanceling(true);
                                cancelSession(sessionData.id).then((res) => {
                                  window.location.reload();
                                });
                              }}
                            >
                              {isCanceling ? (
                                <div
                                  style={{
                                    position: "relative",
                                    transform: "scale(0.4)",
                                    marginTop: "7px",
                                  }}
                                >
                                  <SimpleLoadingIndicator />
                                </div>
                              ) : (
                                "Yes"
                              )}
                            </Button>
                          </div>
                        ) : (
                          <div
                            className="cancel-entry"
                            onClick={() => {
                              setIsCancelOpen(true);
                            }}
                          >
                            Cancel & refund session
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
                {sessionData.status === SessionStatus.EXPIRED && (
                  <Button
                    Theme={"dark"}
                    style={{ width: "200px" }}
                    onClick={() => {
                      // go to the profile of the host
                      window.location.href =
                        "/profile/" +
                        allUserData.filter(
                          (u) => u.uuid === sessionData.ownerId
                        )[0].username;
                    }}
                  >
                    Visit booking page
                  </Button>
                )}
                {sessionData.status === SessionStatus.CANCELLED && (
                  <>
                    {isHost ? (
                      <>
                        <Button
                          Theme={"dark"}
                          style={{ width: "200px" }}
                          onClick={() => {
                            // go to the profile of the host
                            window.location.href = "/";
                          }}
                        >
                          Return home
                        </Button>
                        <div className="book-new-session-tagline">
                          To reschedule, your guest needs to request a new
                          session.
                        </div>
                      </>
                    ) : (
                      <>
                        <Button
                          mobileSz14
                          Theme={"dark"}
                          style={{ width: "200px" }}
                          onClick={() => {
                            // go to the profile of the host
                            window.location.href = `/profile/${
                              allUserData.find(
                                (u) => u.uuid === sessionData.ownerId
                              )!.username
                            }`;
                          }}
                        >
                          Book new session
                        </Button>
                      </>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </StyledSessionPage>
    </div>
  );
};

const StyledSessionPage = styled("div", {
  display: "flex",
  justifyContent: "center",
  marginTop: "108px",
  paddingBottom: "140px",
  overflow: "scroll",
  fontSize: "12px",

  "& .session-box": {
    width: "600px",
    padding: "68px",
    backgroundColor: "white",
    border: "1px solid black",
    borderRadius: "27px",
    boxShadow: "0 3px 0 0 black",
    paddingBottom: "48px",
  },
  "& .session-status": {
    fontSize: "14px",
    "@mobile": {
      fontSize: "16px",
    },
    fontWeight: "500",
    marginBottom: "8px",
  },
  "& .requested-info": {
    marginBottom: "18px",
  },
  "& .link-line": {
    display: "flex",
    justifyContent: "space-between",
    width: "calc(100% + 16px)",
    "& .icon-line": {
      overflow: "hidden",
    },
    "& .link-text": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      marginRight: "8px",
      display: "block",
      width: "100%",
      textDecoration: "none",
      transition: "0.2s all",
      "&:hover": {
        opacity: 0.6,
      },
    },
  },

  "& .icon-line": {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",

    "& img": {
      marginRight: "4px",
    },
  },
  "& .guests-container": {
    backgroundColor: "$wash",
    borderRadius: "14px",
    marginLeft: "-32px",
    marginRight: "-32px",
    width: "100% + 64px",
    padding: "20px 32px",
    marginTop: "18px",
    marginBottom: "28px",
  },

  "& .guest": {
    marginBottom: "8px",
    "& .guest-line-container": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .guest-line": {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    "& .guest-pic": {
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      marginRight: "8px",
    },
    "& .guest-name": {
      marginRight: "8px",
    },
    "& .host-badge": {
      backgroundColor: "$lightPurple",
      fontSize: "9px",
      padding: "2px 8px",
      borderRadius: "8px",
    },
    "& .session-context": {
      fontStyle: "italic",
      marginLeft: "36px",
      lineHeight: "1.4",
    },
  },
  "& .cancel-tagline": {
    marginTop: "24px",
    fontWeight: "500",
  },
  "& .cancel-description": {
    fontWeight: "500",
    fontSize: "12px",
    marginTop: "20px",
  },
  "& .book-new-session-tagline": {
    fontWeight: "normal",
    marginTop: "16px",
  },
  "& .cancel-entry": {
    cursor: "pointer",
    transition: "opacity 0.2s",
    marginTop: "8px",
    "&:hover": {
      opacity: 0.5,
    },
  },
  "@mobile": {
    fontSize: "14px",
    marginTop: "90px",
    "& .session-box": {
      maxWidth: "90%",
      padding: "48px 32px",
      marginTop: "0px",
    },
    "& .guests-container": {
      marginLeft: "-16px",
      marginRight: "-16px",
      padding: "16px",
    },
    "#copy-email-button": {
      marginRight: "-8px !important",
    },
    "#copy-room-link-button": {
      marginRight: "8px !important",
    },
  },
  variants: {
    cancelled: {
      true: {
        "& .icon-line": {
          // strikethrough text
          textDecoration: "line-through",
        },
      },
    },
  },
});
