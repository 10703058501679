import { Service } from "~/api/calendarApi";
import * as luxon from "luxon";
import { styled } from "~/ui/style/stitches.config";

const CONTEXT_CHAR_LIMIT = 300;

export const ServiceInfoPage = ({
  selectedService,
  selected,
  timeSlot,
  timezone,
  name,
  sessionContext,
  setSessionContext,
  onClick,
}: {
  selectedService: Service | undefined;
  selected: Date | undefined;
  timeSlot: string | undefined;
  timezone: string | undefined;
  name: string;
  sessionContext: string;
  setSessionContext: Function;
  onClick: Function;
}) => {
  return (
    <StyledServiceInfoPage className="inset-page">
      <div className="service-info">
        <div className="name line">
          <img src="/images/moon.svg" alt="moon" />
          {`${selectedService?.name} ⸱ $${selectedService?.price}`}
        </div>
        <div className="date line">
          <img src="/images/more-menu/upcoming.svg" alt="calendar" />
          {`${
            selected &&
            luxon.DateTime.fromJSDate(selected).toFormat("cccc, LLLL d")
          }, ${timeSlot?.toLowerCase()}`}
        </div>
        <div className="timezone line">
          <img src="/images/timezone.svg" alt="calendar" />
          {timezone}
        </div>
        <div className="person line">
          <img src="/images/profile.svg" alt="smiley" />
          {name}
        </div>
        <div className="duration line">
          <img src="/images/video.svg" alt="video" />
          {selectedService?.duration === 30 ? "30 min" : "1 hour"}
        </div>
      </div>
      <div className="survey">
        <div className="question">
          What is a theme or question you'd like to explore?
        </div>
        <div className="text-section">
          <div className="character-counter">
            {sessionContext.length}/{CONTEXT_CHAR_LIMIT}
          </div>
          <textarea
            id="context-textarea"
            className="answer"
            rows={4}
            placeholder="Something I'd like clarity on is..."
            maxLength={CONTEXT_CHAR_LIMIT}
            onInput={(e) => {
              setSessionContext((e.target as HTMLTextAreaElement).value);
            }}
            value={sessionContext}
          ></textarea>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="black-button" onClick={() => onClick()}>
            Continue to book
          </div>
        </div>
        <div className="button-subtitle" style={{ textAlign: "center" }}>
          You won't be charged yet.
        </div>
      </div>
    </StyledServiceInfoPage>
  );
};

const StyledServiceInfoPage = styled("div", {
  "& textarea": {
    resize: "none",
    width: "100%",
    height: "100px",
    padding: "12px",
    border: "1px solid rgba(0, 0, 0, 0.2) !important",
    borderRadius: "10px",
    backgroundColor: "white",
    "&:focus": {
      outline: "none",
      boxShadow: "none",
      border: "1px solid black",
    },
  },
});
