import { useEffect, useState } from "react";
import { confirmSession, declineSession } from "~/api/sessionApi";
import { SimpleLoadingIndicator } from "../loading/LoadingPage";

export const SessionAction = () => {
  const [displaySessionId, setDisplaySessionId] = useState("");
  const [displayAction, setDisplayAction] = useState("");
  // get the session id from the url
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("id");
    const action = urlParams.get("action")?.toLowerCase();
    setDisplaySessionId(sessionId || "");
    setDisplayAction(action || "");

    if (sessionId && action) {
      if (action === "decline") {
        declineSession(sessionId)
          .then(() => {
            window.location.href = "/session/" + sessionId;
          })
          .catch((err) => {
            console.log(err);
            window.location.href = "/session/" + sessionId;
          });
      } else if (action === "accept") {
        confirmSession(sessionId)
          .then(() => {
            window.location.href = "/session/" + sessionId;
          })
          .catch((err) => {
            console.log(err);
            window.location.href = "/session/" + sessionId;
          });
      }
    }
  }, [window.location.search]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {<SimpleLoadingIndicator />}
    </div>
  );
};
