import { useContext, useEffect, useRef, useState } from "react";
import { useSnapshot } from "valtio";
import { useLocation } from "wouter";
import {
  addToCartIds,
  loadCartIds,
  localState,
  removeFromCartIds,
  saveCartIds,
} from "~/state/state";
import { Deck, freeDeckIds } from "~/types/room/types";
import { Button } from "~/ui/components/Button";
import { SecurePaymentsDisclaimer } from "~/ui/components/DisclaimerContainer";
import { allDecks } from "~/ui/menus/DeckMenu";
import { styled } from "~/ui/style/stitches.config";
import { DECK_PRICE } from "~/utils/consts";
import { DeckAnimation } from "./DeckMarketplace";
import { UserProfile, getUserByUsername } from "~/api/userApi";
import { DeckResponse, getDeckById } from "~/api/deckApi";
import { StripeDeckCheckout } from "~/ui/components/StripeCheckout";
import { AuthContext } from "~/utils/useAuth";
import { QuestionHover } from "~/ui/components/Tooltip";
import { BookingSignInPage } from "./booking/BookingSignInPage";
import { imageUrl } from "~/utils/imageurl";
import { trackEvent } from "~/api/analyticsApi";

export const SuccessView = () => {
  const { currCartIds } = useSnapshot(localState);
  return (
    <StyledSuccessOverlayView>
      <img
        src="/images/room/x-icon.png"
        className="x-button"
        onClick={(e) => {
          localState.cartPage = CartPage.CLOSED;
          e.stopPropagation();
        }}
      />
      <div className="success-text">
        <div className="success-title">Successfully added!</div>
        <div className="success-message">
          You can use your decks in any Moonlight room, including rooms you’ve
          already created.
        </div>
        <div className="button-container">
          <Button
            onClick={() => {
              localState.cartPage = CartPage.CLOSED;
              window.location.href = "/";
            }}
          >
            Return home
          </Button>
          <Button
            darkHover
            onClick={() => {
              document.getElementById("create-room-button")?.click();
            }}
          >
            Create a room
          </Button>
        </div>
      </div>
    </StyledSuccessOverlayView>
  );
};
export const SignupView = () => {
  const { currCartIds } = useSnapshot(localState);
  return (
    <StyledSignupOverlayView>
      <BookingSignInPage
        price={DECK_PRICE * currCartIds.length}
        hostUserName={"Deck purchase. Deck IDs: " + currCartIds.join(", ")}
        onSubmitSignIn={() => {
          trackEvent("/decks/cart/sign_in_up/continued");
          localState.cartPage = CartPage.CHECKOUT;
        }}
      />
    </StyledSignupOverlayView>
  );
};

export const CartView = () => {
  const [deckIdsInCart, setDeckIdsInCart] = useState<string[]>([]);
  const [_, setLocation] = useLocation();

  const { user } = useContext(AuthContext);

  const { isOnMobile } = useSnapshot(localState);

  useEffect(() => {
    setDeckIdsInCart(loadCartIds());
  }, []);

  const goToDeck = (deck: Deck) => {
    localState.recentCartDeck = deck;
    localState.cartPage = CartPage.DECK;
  };

  const deckCartItems = deckIdsInCart.map((deckId) => {
    const deck = allDecks.find((d) => d.id === deckId);
    if (!deck) return null;
    return (
      <div key={deckId} className="cart-item">
        <img
          className="deck-preview"
          onClick={() => goToDeck(deck)}
          src={imageUrl("/decks/deck-previews/" + deck.preview, 200)}
        />
        <div className="text-container">
          <div className="deck-name" onClick={() => goToDeck(deck)}>
            {deck.name}
          </div>
          <div className="deck-tagline">
            <span className="tagline-label">DIGITAL DECK + GUIDE</span>
            <span
              style={{
                fontStyle: "none !important",
                marginTop: "-1px",
              }}
            >
              {" "}
              <QuestionHover
                menuStyle={{
                  textAlign: "left",

                  textWrap: "wrap",
                }}
                tooltipStyle={{
                  width: "160px",
                  textWrap: "wrap",
                }}
                vertical={isOnMobile}
                text={
                  "<b>Access anytime.</b><br/>Use in-browser across all Moonlight rooms.<br/><br/><b>Explore and interact.</b><br/>Shuffle & drag cards solo or collaboratively."
                }
              />
            </span>
          </div>
          <div className="deck-price">${DECK_PRICE}</div>
        </div>
        <img
          src="/images/room/x-icon.png"
          className="x-button deck-x-button"
          onClick={(e) => {
            removeFromCartIds(deck.id);
            setDeckIdsInCart(loadCartIds());
            e.stopPropagation();
          }}
        />
      </div>
    );
  });

  return (
    <StyledCartOverlayView>
      <img
        src="/images/room/x-icon.png"
        className="x-button"
        onClick={(e) => {
          localState.cartPage = CartPage.CLOSED;
          e.stopPropagation();
        }}
      />
      {deckCartItems.length > 0 ? (
        <>
          <div className="cart-title">Your Selected Decks</div>

          <div className="deck-cart-items">{deckCartItems}</div>
          <div className="total-price">
            <div className="total">Total</div>
            <div className="price">{`$${
              DECK_PRICE * deckIdsInCart.length
            } USD`}</div>
          </div>
          <div className="cart-button-container">
            <Button
              onClick={(e) => {
                localState.cartPage = CartPage.CLOSED;
                setLocation("/decks");
                e.stopPropagation();
              }}
            >
              Keep browsing
            </Button>
            <Button
              darkHover
              onClick={(e) => {
                if (!user) {
                  trackEvent("/decks/cart/sign_in_up/viewed");
                  localState.cartPage = CartPage.SIGNUP;
                  e.stopPropagation();
                } else {
                  trackEvent("/decks/cart/pressed_checkout");
                  localState.cartPage = CartPage.CHECKOUT;
                  e.stopPropagation();
                }
              }}
            >
              Continue
            </Button>
          </div>
          <SecurePaymentsDisclaimer
            text={"Access decks instantly after purchase."}
            style={{
              fontSize: "11px",
              textWrap: isOnMobile ? "wrap" : "nowrap",
              marginTop: "6px",
            }}
          />
        </>
      ) : (
        <div className="nothing-in-cart">
          <div className="title">There's nothing in your cart yet.</div>
          <Button
            style={{ padding: "16px 30px", borderRadius: "19px" }}
            onClick={() => {
              localState.cartPage = CartPage.CLOSED;
              setLocation("/decks");
            }}
          >
            Browse decks
          </Button>
        </div>
      )}
    </StyledCartOverlayView>
  );
};

export const CheckoutView = () => {
  const { user, processDeckPayment } = useContext(AuthContext);
  const [isSubmittingPayment, setIsSubmittingPayment] = useState(false);
  const { isOnMobile } = useSnapshot(localState);

  const [deckIds, setDeckIds] = useState<string[]>([]);
  useEffect(() => {
    setDeckIds(loadCartIds());
  }, []);

  // TODO FIX THE PRICE
  return (
    <StyledCartOverlayView>
      <img
        src="/images/room/x-icon.png"
        className="x-button"
        onClick={(e) => {
          localState.cartPage = CartPage.CLOSED;
          e.stopPropagation();
        }}
      />
      <div className="cart-title">
        <div className="total-decks">{`Total (${
          deckIds.length > 1 ? `${deckIds.length} decks` : `1 deck`
        })`}</div>
        <div className="total-price">{`$${
          DECK_PRICE * deckIds.length
        } USD`}</div>
      </div>
      {deckIds.length > 0 && (
        <StripeDeckCheckout
          deckIds={deckIds}
          amount={deckIds.length * DECK_PRICE * 100}
          useremail={user ? user.email : ""}
          metadata={{
            deckIds: deckIds.join(","),
            userId: user?.uuid || "",
            userName: user?.username || "",
          }}
          buttonStyle={{
            width: "100%",
            backgroundColor: "#EFC1FF",
            marginTop: "20px",
          }}
          onSuccess={async (
            deckIds: string[],
            paymentIntentId: string,
            paymentGroupId: string
          ): Promise<void> => {
            // create the session booking request
            if (!user || !deckIds || !paymentGroupId) return;
            await processDeckPayment(deckIds, paymentIntentId, paymentGroupId);
            saveCartIds([]);
            trackEvent("/decks/purchased_decks", {
              deckIds: deckIds.join(","),
            });

            localState.cartPage = CartPage.SUCCESS;
          }}
          setIsSubmittingPayment={setIsSubmittingPayment}
          isSubmittingPayment={isSubmittingPayment}
        />
      )}
      <SecurePaymentsDisclaimer
        text={"Access decks anytime after purchase."}
        style={{
          fontSize: "11px",
          textWrap: isOnMobile ? "wrap" : "nowrap",
          marginTop: "6px",
        }}
      />
    </StyledCartOverlayView>
  );
};

export const DeckView = () => {
  const { recentCartDeck, isOnMobile } = useSnapshot(localState);
  const [deckIdsInCart, setDeckIdsInCart] = useState<string[]>([]);
  const [isAnimationStarted, setIsAnimationStarted] = useState(false);
  const [deckPros, setDeckPros] = useState<UserProfile[]>([]);
  const isDeckDataLoaded = useRef(false);
  const isDeckDataError = useRef(false);
  const [deckData, setDeckData] = useState<DeckResponse | null>(null);
  const [location, setLocation] = useLocation();

  const { user } = useContext(AuthContext);

  useEffect(() => {
    window.setTimeout(() => {
      setIsAnimationStarted(true);
    }, 800);
  }, []);

  useEffect(() => {
    setDeckIdsInCart(loadCartIds());
  }, []);

  useEffect(() => {
    if (isDeckDataLoaded.current || isDeckDataError.current) return;
    if (!recentCartDeck) return;

    isDeckDataLoaded.current = false;
    isDeckDataError.current = false;
    if (recentCartDeck.name) {
      trackEvent("/decks/open_deck_page", {
        deckName: recentCartDeck.name,
      });
    }

    (async () => {
      try {
        if (freeDeckIds.includes(recentCartDeck.id)) return;
        const deckResponse = await getDeckById(recentCartDeck.id);
        // Process deckResponse and handle success case

        try {
          const allDeckPros = await Promise.all(
            deckResponse?.proIds?.map(async (proId) => {
              const proUser = await getUserByUsername(proId);
              return proUser;
            }) || []
          );
          setDeckPros(allDeckPros);
          setDeckData(deckResponse);
        } catch (error) {
          isDeckDataError.current = true;
        }
        isDeckDataLoaded.current = true;
      } catch (error) {
        // Handle error case and set isDeckDataError to true
        isDeckDataError.current = true;
      }
    })();
  }, [recentCartDeck?.name]);

  const buttonContainer = !(
    recentCartDeck &&
    (user?.decks?.includes(recentCartDeck?.id) ||
      freeDeckIds.includes(recentCartDeck?.id))
  ) ? (
    <div className="button-container">
      <Button
        darkHover={
          !(
            recentCartDeck &&
            (user?.decks?.includes(recentCartDeck?.id) ||
              freeDeckIds.includes(recentCartDeck?.id))
          )
        }
        onClick={(e) => {
          if (recentCartDeck) {
            if (
              user?.decks?.includes(recentCartDeck.id) ||
              freeDeckIds.includes(recentCartDeck.id)
            ) {
              localState.cartPage = CartPage.CLOSED;
              e.stopPropagation();
            } else if (deckIdsInCart.includes(recentCartDeck.id)) {
              localState.cartPage = CartPage.CART;
              e.stopPropagation();
            } else {
              trackEvent("/decks/added_to_cart", {
                deckName: recentCartDeck.name,
              });
              addToCartIds(recentCartDeck.id);
              setDeckIdsInCart(loadCartIds());
              localState.cartPage = CartPage.CART;
              e.stopPropagation();
            }
          }
        }}
      >
        {recentCartDeck && deckIdsInCart.includes(recentCartDeck?.id)
          ? "Complete checkout"
          : "Open on Moonlight"}
      </Button>
    </div>
  ) : (
    <div className="button-container double-button">
      <Button
        darkHover={false}
        onClick={(e) => {
          localState.cartPage = CartPage.CLOSED;
        }}
      >
        Keep browsing
      </Button>
      <Button
        darkHover={true}
        onClick={(e) => {
          // add param ?deck={deckId} to the url
          setLocation(location + `?deck=${recentCartDeck?.id}`);
          trackEvent("/decks/open_room", {
            deckName: recentCartDeck?.name,
          });
          document.getElementById("create-room-button")!.click();
        }}
      >
        Start a ritual
      </Button>
    </div>
  );

  useEffect(() => {
    if (!isAnimationStarted && isOnMobile)
      window.setTimeout(() => setIsAnimationStarted(true), 1500);
  }, [isAnimationStarted]);

  const deckTags = recentCartDeck?.tags ? (
    <div className="deck-tags">
      {recentCartDeck?.tags.map((tag) => (
        <div id={tag} className="tag">
          {tag}
        </div>
      ))}
    </div>
  ) : (
    <></>
  );

  return (
    <StyledDeckOverlayView>
      <img
        src="/images/room/x-icon.png"
        className="x-button"
        onClick={(e) => {
          localState.cartPage = CartPage.CLOSED;
          e.stopPropagation();
        }}
      />
      <div className="cols-container">
        <span className="show-mobile">{deckTags}</span>

        <div className="left-col">
          {recentCartDeck && (
            <div
              className="deck-anim-container"
              onPointerLeave={() => {
                if (!isOnMobile) setIsAnimationStarted(false);
              }}
              onPointerEnter={() => {
                if (!isOnMobile) setIsAnimationStarted(true);
              }}
              onClick={() => {
                if (isOnMobile) {
                  setIsAnimationStarted(false);
                }
              }}
            >
              <div className="deck-anim">
                <DeckAnimation
                  deck={recentCartDeck as Deck}
                  isHovered={isAnimationStarted}
                />
              </div>
            </div>
          )}
        </div>

        <div className="right-col">
          <img
            className="deck-preview"
            src={imageUrl(
              "/decks/deck-previews/" + recentCartDeck?.preview,
              200
            )}
          />
          <div className="deck-name">{recentCartDeck?.name}</div>
          <div className="creators-container">
            <h2 className="creators">
              {deckPros.length > 0 || deckData?.additionalPros ? (
                <>
                  {deckPros.map((pro) => (
                    <div
                      className="pro-label"
                      key={"pro-label" + pro.username}
                      onClick={() => {
                        //open their profile in a new tab
                        window.open(`/profile/${pro.username}`, "_blank");
                      }}
                    >
                      <img
                        src={imageUrl(`/profile-pics/${pro.profilePic}`, 36)}
                      />
                      {pro.name}
                    </div>
                  ))}
                  {deckPros.length > 0 &&
                    deckData?.additionalPros &&
                    deckData.additionalPros.length > 0 && (
                      <div className="ampersand">&</div>
                    )}
                  {deckData?.additionalPros}
                </>
              ) : (
                <>{recentCartDeck?.creator}</>
              )}
            </h2>
          </div>
          <div className="deck-location">{`${recentCartDeck?.location}, ${recentCartDeck?.year}`}</div>
          <div
            className={`deck-price in-collection-${
              recentCartDeck &&
              (user?.decks?.includes(recentCartDeck.id) ||
                freeDeckIds.includes(recentCartDeck.id))
            }`}
          >
            {recentCartDeck &&
            (user?.decks?.includes(recentCartDeck.id) ||
              freeDeckIds.includes(recentCartDeck.id))
              ? "✓ IN YOUR COLLECTION"
              : "$" + DECK_PRICE}
          </div>

          <div className="deck-type">
            <span className="deck-type-label">
              {`${
                recentCartDeck?.name.toLowerCase().includes("viator")
                  ? 52
                  : recentCartDeck?.cards.length
              } cards, digital ${
                recentCartDeck?.name.toLowerCase().includes("viator")
                  ? "oracle"
                  : "tarot"
              } deck + guide`}
            </span>
            <span
              style={{
                fontStyle: "none !important",
                marginTop: "-1px",
              }}
            >
              {" "}
              <QuestionHover
                menuStyle={{
                  textAlign: "left",
                  textWrap: "wrap",
                }}
                tooltipStyle={{
                  width: "160px",
                  textWrap: "wrap",
                }}
                vertical={isOnMobile}
                text={
                  "<b>Access anytime.</b><br/>Use in-browser across all Moonlight rooms.<br/><br/><b>Explore and interact.</b><br/>Shuffle & drag cards solo or collaboratively."
                }
              />
            </span>
          </div>
          <span className="show-mobile">{buttonContainer}</span>
          <div className="deck-description">{recentCartDeck?.description}</div>
          <span className="hide-mobile">{deckTags}</span>
          <span className="hide-mobile">{buttonContainer}</span>
        </div>
      </div>
    </StyledDeckOverlayView>
  );
};

const StyledCartOverlayView = styled("div", {
  "& .show-mobile": {
    display: "none",
    "@mobile": {
      display: "block",
    },
  },
  "& .hide-mobile": {
    display: "block",
    "@mobile": {
      display: "none",
    },
  },
  maxHeight: "90%",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    webkitAppearance: "none",
    width: "8px",
    marginLeft: "1px",
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: "20px",
    backgroundColor: "rgba(0, 0, 0, .5)",
    border: "1px solid $wash",
  },
  "&::-webkit-scrollbar-track": {
    overflow: "hidden",
    marginTop: "60px",
    marginBottom: "30px",
    width: "8px",
  },
  width: "500px",
  backgroundColor: "$wash",
  padding: "70px 60px",
  border: "1px solid black",
  borderRadius: "33px",
  position: "relative",
  "& .x-button": {
    top: "20px",
    right: "20px",
    width: "20px",
    height: "20px",
    filter: "invert(1)",
    cursor: "pointer",
    opacity: "0.8",
    "&:hover": {
      opacity: "0.5",
    },
  },
  "& .cart-title": {
    fontFamily: "KeplerLightCondensed",
    fontSize: "27px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    "& .total-decks": {
      fontWeight: "400",
    },
    "& .total-price": {
      fontWeight: "400",
      width: "auto",
      fontFamily: "WorkSans",
      fontSize: "20px",
    },
  },
  "& .deck-cart-items": {
    marginTop: "20px",
    marginBottom: "44px",
  },
  "& .cart-item": {
    display: "flex",

    position: "relative",
    marginTop: "20px",
    width: "100%",
    height: "48px",
    "& .deck-preview": {
      borderRadius: "9px",
      marginRight: "9px",
      height: "100%",
      aspectRatio: "1/1",
      cursor: "pointer",
    },
    "& .text-container": {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      "& .deck-name": {
        fontSize: "13px",
        fontWeight: "500",
        cursor: "pointer",
      },
      "& .deck-tagline": {
        fontSize: "11px",
        fontWeight: "200",
        display: "flex",
      },
      "& .tagline-label": {
        fontStyle: "italic",
      },
      "& .deck-price": {
        fontSize: "13px",
      },
    },

    "& .x-button": {
      top: 0,
      right: 0,
    },
  },
  "& .total-price": {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: "24px",
    "& .total": {
      fontFamily: "KeplerLightCondensed",
      fontSize: "27px",
    },
    "& .price": {
      fontSize: "18px",
      fontWeight: "400",
    },
  },
  "& .cart-button-container": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    gap: "8px",
    "& button": {
      borderRadius: "19px",
      padding: "16px 0",
      width: "calc(50% - 4px)",
    },
  },
  "& .nothing-in-cart": {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    "& .title": {
      fontSize: "27px",
      fontFamily: "KeplerLightCondensed",
      marginBottom: "20px",
      textAlign: "center",
    },
    "& button": {
      alignSelf: "center",
    },
  },
  overflowX: "hidden",

  "@mobile": {
    width: "100%",
    height: "100%",
    padding: "70px 30px",
    maxHeight: "unset",
    margin: 0,
    borderRadius: 0,
    border: "none",
    overflowX: "hidden",
    "& .x-button": {
      transform: "scale(1.5)",
      position: "fixed",
    },
    "& button": {
      fontSize: "15px",
    },
    "& .cart-item ": {
      height: "56px",
      "& .x-button": {
        transform: "scale(1.2)",
        opacity: 0.5,
      },
      "& .text-container": {
        "& .deck-name": {
          fontSize: "16px",
        },
        "& .deck-tagline": {
          fontSize: "12px",
        },
        "& .deck-price": {
          fontSize: "16px",
        },
      },
    },
  },
});

const StyledSuccessOverlayView = styled(StyledCartOverlayView, {
  "& .success-title": {
    fontSize: "14px",
    fontWeight: "500",
    marginBottom: "10px",
  },
  "& .success-message": {
    fontSize: "12px",
    marginBottom: "30px",
  },
  "& .button-container": {
    width: "100%",
    display: "flex",
    gap: "8px",
    "& button": {
      width: "calc(50% - 4px)",
    },
  },
  "@mobile": {
    "& .success-title": {
      fontSize: "18px",
    },
    "& .success-message": {
      fontSize: "14px",
    },
  },
});

const StyledSignupOverlayView = styled(StyledCartOverlayView, {});

const StyledDeckOverlayView = styled(StyledCartOverlayView, {
  display: "flex",
  flexDirection: "row",
  width: "760px !important",
  "& .cols-container": {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  "& .left-col": {
    width: "40%",
    padding: "14px 0",
    minHeight: "440px",

    backgroundColor: "white",
    marginRight: "40px",
    borderRadius: "19px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& .deck-anim": {
      marginLeft: "-10px",
      marginTop: "40px",
    },
  },
  "& .right-col": {
    width: "60%",
  },

  "& .creators-container": {
    maxWidth: "calc(100% - 90px)",
    textWrap: "wrap",
    // display: "inline-block",
    verticalAlign: "middle",
    lineHeight: "12px",
    height: "22px",
    marginTop: "0px",
  },
  "& .creators": {
    fontSize: "12px",
    fontWeight: "normal",
    verticalAlign: "middle",
    textWrap: "wrap",
    // set max num of rows
    maxHeight: "40px",
    overflow: "hidden",
    lineHeight: "14px",
    paddingTop: "4px",
    textOverflow: "ellipsis",
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    "& .pro-label": {
      fontWeight: "500",
      cursor: "pointer",
      display: "inline-block",
      verticalAlign: "middle",
      textWrap: "wrap",
    },
    "& img": {
      width: "18px",
      height: "18px",
      borderRadius: "50%",
      marginRight: "4px",
      verticalAlign: "middle",
      marginTop: "-2px",
    },
    "& .ampersand": {
      marginLeft: "4px",
      marginRight: "4px",
      display: "inline",
    },
  },

  "& .deck-preview": {
    width: "90px",
    height: "90px",
    borderRadius: "9px",
  },
  "& .deck-name": {
    fontSize: "27px",
    fontFamily: "KeplerLightCondensed",
    marginTop: "20px",
  },
  "& .deck-author": {
    fontSize: "14px",
    marginTop: "0px",
    marginBottom: "4px",
  },
  "& .deck-location, .deck-type": {
    fontSize: "12px",
  },
  "& .deck-type": {
    marginBottom: "16px",
    marginTop: "4px",
    fontWeight: "200",
    display: "flex",
    alignItems: "center",
  },
  "& .deck-type-label": {
    fontStyle: "italic",
  },
  "& .deck-description": {
    fontSize: "13px",
    lineHeight: "1.2",
    marginBottom: "20px",
  },
  "& .deck-price": {
    marginTop: "20px",
    fontSize: "16px",
    "&.in-collection-true": {
      fontSize: "12px",
      fontWeight: "300",
      color: "#5d5d5d",
    },
  },
  "& .deck-tags": {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "10px",
    width: "calc(100% + 16px)",
    marginLeft: "-8px",
    marginBottom: "50px",
    "& .tag": {
      backgroundColor: "white",
      textTransform: "uppercase",
      fontSize: "9px",
      width: "calc(33% - 8px)",
      marginRight: "4px",
      marginLeft: "4px",
      textAlign: "center",
      borderRadius: "18px",
      padding: "4px",
      marginTop: "4px",
    },
  },
  "& .button-container": {
    width: "100%",
    display: "flex",

    justifyContent: "flex-start",
    "& button": {
      borderRadius: "19px",
      padding: "16px 56px",
    },
    "&.double-button": {
      justifyContent: "space-between",
      "& button": {
        width: "calc(50% - 4px)",
        padding: "16px 0",
      },
    },
    "@mobile": {
      marginBottom: "20px",
      marginTop: "20px",
    },
  },

  "@mobile": {
    paddingTop: "20px",
    flexDirection: "column",
    "& .cols-container": {
      display: "flex",
      flexDirection: "column-reverse",
    },
    "& .deck-tags": {
      marginBottom: "30px",
    },
    "& .left-col": {
      width: "100%",
      height: "410px",
      minHeight: "unset",
      paddingTop: "0",
      display: "flex",
      justifyContent: "center",
      "& .deck-anim": {
        transform: "scale(1)",
        transformOrigin: "bottom center",
        marginTop: "50px",
      },
    },
    "& .right-col": {
      width: "100%",
      marginTop: "30px",
      "& .deck-name": {
        fontSize: "31px",
      },
      "& .creators": {
        fontSize: "14px",
      },
      "& .deck-location": {
        fontSize: "13px",
      },
      "& .deck-type": {
        fontSize: "14px",
      },
      "& .deck-price": {
        fontSize: "20px",
        "&.in-collection-true": {
          fontSize: "14px",
        },
      },
      "& .deck-description": {
        fontSize: "14px",
      },
      "& .tag": {
        fontSize: "10px",
      },
    },
  },
});

export enum CartPage {
  DECK = "DECK",
  CART = "CART",
  CHECKOUT = "CHECKOUT",
  CLOSED = "CLOSED",
  SIGNUP = "SIGNUP",
  SUCCESS = "SUCCESS",
}

export const CartOverlay = () => {
  const { recentCartDeck, cartPage } = useSnapshot(localState);

  return (
    <StyledCartOverlay
      isCartOpen={cartPage !== CartPage.CLOSED}
      onClick={(e) => {
        if (e.target === e.currentTarget) localState.cartPage = CartPage.CLOSED;
      }}
    >
      {cartPage === CartPage.CART ? (
        <CartView />
      ) : cartPage === CartPage.DECK ? (
        <DeckView />
      ) : cartPage === CartPage.CHECKOUT ? (
        <CheckoutView />
      ) : cartPage === CartPage.SIGNUP ? (
        <SignupView />
      ) : cartPage === CartPage.SUCCESS ? (
        <SuccessView />
      ) : (
        <div>Empty</div>
      )}
    </StyledCartOverlay>
  );
};

const StyledCartOverlay = styled("div", {
  width: "100%",
  height: "100%",
  position: "fixed",
  top: 0,
  left: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 999999,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& .x-button": {
    position: "absolute",
  },
  "& .deck-x-button": {
    position: "absolute !important",
  },
  variants: {
    isCartOpen: {
      true: {},
      false: {
        display: "none",
      },
    },
  },
});
