import { styled } from "@stitches/react";

import { Hand } from "../../ui/room/Hand";
import { Spread } from "../../ui/room/Spread";
import {
  RoomState,
  RoomTheme,
  SpreadText,
  freeDeckIds,
  videoDeckIds,
} from "../../types/room/types";
import { localState } from "~/state/state";

import { Shuffle } from "./Shuffle";
import { AV } from "../../av/AV";
import { AnimatePresence, motion } from "framer-motion";
import { Perspective } from "../../ui/room/Perspective";
import {
  initialStorage,
  RoomProvider,
  useMutateStorage,
  useStorage,
} from "../../state/liveblocks.config";
import { ClientSideSuspense, shallow } from "@liveblocks/react";
import { ActionPortalTarget } from "../../ui/room/Actions";
import { Cursors } from "../../ui/room/Cursors";
import { ThemedApp } from "../../ui/style/ThemedApp";
import { useSnapshot } from "valtio";
import { BottomBar } from "~/ui/menus/BottomBar";
import { RoomMenus } from "~/ui/menus/RoomMenus";
import { useRequireAuth } from "~/utils/useRequireAuth";
import { LoadingPage } from "../loading/LoadingPage";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { getRoom } from "~/api/roomApi";
import {
  MAX_ROOM_ATTENDEES,
  MOBILE_ZOOM_INDEX_MOD,
  PAN_BASE,
  ZOOM_BASE,
} from "~/utils/consts";
import { RoomFull } from "../404/RoomFull";
import { MobileMessage } from "~/ui/components/MobileMessage";
import roomImagesToPreload from "~/preloading/roomImagesToPreload.json";
import { ImagePreloader } from "~/preloading/Preloader";
import { SpreadTextOverlay } from "~/ui/room/SpreadText";
import { LiveList } from "@liveblocks/client";
import { Vec2d } from "~/utils/useMousePosition";
import { UserProfile } from "~/api/userApi";
import { allDecks } from "~/ui/menus/DeckMenu";
import { imageUrl } from "~/utils/imageurl";
import { trackEvent } from "~/api/analyticsApi";
import { allSpreads } from "~/ui/menus/SpreadMenu";

export const PERSPECTIVE_SHUFFLE = 1000;
export const ANGLE_SHUFFLE = 35;
export const PERSPECTIVE_DRAW = 1000;
export const ANGLE_DRAW = 26;

export const Room = React.memo(function Room({
  id,
  user,
}: {
  id: string;
  user?: UserProfile;
}) {
  const _auth = useRequireAuth();
  return (
    <RoomProvider
      id={id}
      initialPresence={{
        cursor: null,
        prevCursor: null,
        activeShape: null,
        color: "",
      }}
      initialStorage={initialStorage}
    >
      <ClientSideSuspense
        fallback={<LoadingPage connectionPage={true} noText={true} />}
      >
        {() => <RoomContents id={id} user={user} />}
      </ClientSideSuspense>
    </RoomProvider>
  );
});

const RoomContents = React.memo(function RoomContents({
  id,
  user,
}: {
  id: string;
  user?: UserProfile;
}) {
  const deck = useStorage((root) => root.deck, shallow);
  const background = useStorage((root) => root.background);
  const theme = useStorage((root) => root.theme);
  const reversesOn = useStorage((root) => root.reversesOn);
  const inRoomUsernames = useStorage((root) => root.usernames);
  const isCardSearchOpen = useStorage((root) => root.isCardSearchOpen);
  const state = useStorage((root) => root.state);

  const [isAVLoaded, setIsAVLoaded] = useState(false);
  const [isRoomFull, setIsRoomFull] = useState(false);
  const [areImagesPreloaded, setAreImagesPreloaded] = useState(false);

  useEffect(() => {
    // add username to liveblocks list of usernames for the room
    if (!user?.username) return;
    updateStorage((storage) => {
      storage.set("usernames", [
        ...inRoomUsernames.filter((n) => n !== user?.username),
        user?.username,
      ]);
    });
  }, [user?.username]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      trackEvent("/room/exit_room");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    trackEvent("/room/visited_page", {
      room_id: id,
    });

    // get current deck and spread from query params
    const urlParams = new URLSearchParams(window.location.search);
    const deckId = urlParams.get("deck");
    const spreadId = urlParams.get("spread");
    const deck = allDecks.find((d) => d.id === deckId);
    const spread = allSpreads.find((s) => s.id === spreadId);

    if (spread) {
      updateStorage((storage) => {
        storage.set("spread", spread);
        storage.set("cards", new LiveList([]));
        storage.set("state", RoomState.Ready);
      });
    }
    if (deck && videoDeckIds.includes(deck.id)) {
      updateStorage((storage) => {
        storage.set("reversesOn", false);

        updateStorage((storage) => {
          storage.set("theme", "dark");
        });
      });
    }
    if (
      deck &&
      (user?.decks.includes(deck.id) || freeDeckIds.includes(deck.id))
    ) {
      updateStorage((storage) => {
        deck.cards.forEach((card) => {
          card.filePath = deck.basePath + "/" + card.artwork;
        });
        storage.set("deck", deck);
        storage.set("cards", new LiveList([]));
        storage.set("state", RoomState.Shuffle);
      });
    }
  }, []);

  const updateStorage = useMutateStorage();
  const {
    inDrawingMode,
    inTypingMode,
    isGuidebookOpen,
    isInRoom,
    pan,
    isOnMobile,
    zoomIndex,
    isFocusedTyping,
    isOnPlusPlan,
    panScreenDelta,
  } = useSnapshot(localState);
  const zoomIndexMod = isOnMobile ? MOBILE_ZOOM_INDEX_MOD : 0;
  const panScreenDeltaRef = useRef(panScreenDelta);
  useEffect(() => {
    panScreenDeltaRef.current = panScreenDelta;
  }, [panScreenDelta]);
  const zoomVector = [
    ZOOM_BASE ** (zoomIndex + zoomIndexMod),
    ZOOM_BASE ** (zoomIndex + zoomIndexMod),
  ] as Vec2d;

  let isInDrawState = null;
  if (!isOnMobile)
    isInDrawState = useStorage(
      (root) => root.state === RoomState.Draw,
      shallow
    );

  useEffect(() => {
    if (isInRoom) localState.isInRoom = false;
    else {
      localState.isInRoom = true;
    }
  }, []);

  useEffect(() => {
    if (!id) return;
    (async () => {
      const room = await getRoom(id);
      if (!room) return;
      if (
        room.numParticipants &&
        room.numParticipants > MAX_ROOM_ATTENDEES - 1
      ) {
        setIsRoomFull(true);
      }
    })();
  }, [id]);

  useEffect(() => {
    localState.inDarkMode = theme === "dark" ? true : false;
    return () => {
      localState.inDarkMode = false;
    };
  }, [theme]);

  // track connection status app-wide
  useEffect(() => {
    if (isAVLoaded) {
      localState.isConnected = true;
    } else localState.isConnected = false;
  }, [isAVLoaded]);

  if (isRoomFull) return <RoomFull />;

  const [startDragPos, setStartDragPos] = useState<Vec2d>([0, 0]);

  const shouldDrag = (
    e:
      | React.MouseEvent<HTMLDivElement>
      | React.TouchEvent<HTMLDivElement>
      | MouseEvent
      | TouchEvent
      | PointerEvent
  ) => {
    if (
      (e.target as HTMLElement).classList.contains("card") ||
      (e.target as HTMLElement).classList.contains("info-box-container") ||
      // check if type <image> instead of <img> because of SVGs
      (e.target as SVGImageElement).href ||
      // check if type input
      (e.target as HTMLInputElement).type ||
      (e.target as HTMLTextAreaElement).type ||
      (e.target as HTMLElement).draggable ||
      (e.target as HTMLElement).classList.contains("spread-text")
    ) {
      return false;
    }
    if (inTypingMode || inDrawingMode) return false;
    if (state === RoomState.Shuffle || state === RoomState.Ready) return false;

    return true;
  };

  const [isDragging, setIsDragging] = useState(false);

  const startDrag = (x: number, y: number) => {
    setStartDragPos([x, y]);
    setIsDragging(true);
  };

  const moveDrag = (x: number, y: number) => {
    if (!startDragPos) return;
    const MAX_PAN = [1400, 1400];
    const SPEED_SCALE = 1.6;

    const deltaX =
      ((x - startDragPos[0]) * SPEED_SCALE) / ZOOM_BASE ** zoomIndex;
    const deltaY =
      ((y - startDragPos[1]) * SPEED_SCALE) / ZOOM_BASE ** zoomIndex;
    if (
      Math.abs(localState.pan[0] * PAN_BASE + deltaX) > MAX_PAN[0] ||
      Math.abs(localState.pan[1] * PAN_BASE + deltaY) > MAX_PAN[1]
    )
      return;

    localState.panScreenDelta = [deltaX, deltaY];
  };

  const endDrag = () => {
    localState.pan = [
      localState.pan[0] + localState.panScreenDelta[0] / PAN_BASE,
      localState.pan[1] + localState.panScreenDelta[1] / PAN_BASE,
    ];
    localState.panScreenDelta = [0, 0];
    setIsDragging(false);
  };

  const addNewText = (x: number, y: number) => {
    const currentPanScreenDelta = panScreenDeltaRef.current;
    const pos = [
      (x - window.innerWidth / 2) / zoomVector[0] - pan[0] * PAN_BASE,
      (y - window.innerHeight / 2) / zoomVector[1] - pan[1] * PAN_BASE,
    ] as [number, number];
    const newText: SpreadText = {
      position: pos,
      text: "",
      id: Math.random().toString(),
      owner: user?.uuid ? user.uuid : "",
    };
    updateStorage((storage) => {
      storage.set("texts", new LiveList([...storage.get("texts"), newText]));
    });
  };

  return (
    <ThemedApp
      id="themed-app"
      style={{
        background: `${
          background.filename
            ? `url("${imageUrl(`/backgrounds/${background.filename}`)}"),`
            : ""
        } radial-gradient(farthest-side, rgba(0,0,0,0) 66%, rgba(0,0,0,0.03))`,
      }}
      mode={inTypingMode ? "type" : inDrawingMode ? "pen" : "auto"}
      isFocusedTyping={isFocusedTyping}
      Theme={theme}
      onMouseDown={() => (localState.isMouseDown = true)}
      onMouseUp={() => (localState.isMouseDown = false)}
      onKeyDown={(e) => {
        if (e.key === "p") {
          localState.inDrawingMode = !inDrawingMode;
        } else if (e.key === "t") {
          localState.inTypingMode = !localState.inTypingMode;
        } else if (e.key === "r") {
          updateStorage((storage) => {
            storage.set("reversesOn", !reversesOn);
          });
        } else if (e.key === "n") {
          localState.isNotebookOpen = !localState.isNotebookOpen;
        } else if (e.key === "g") {
          localState.isGuidebookOpen = !isGuidebookOpen;
        } else if (e.key === "s") {
          updateStorage((storage) => {
            storage.set("isCardSearchOpen", !isCardSearchOpen);
          });
        }
      }}
      onClick={(e) => {
        if (!inTypingMode) return;
        if (isFocusedTyping) {
          localState.isFocusedTyping = false;
          return;
        }
        addNewText(e.clientX, e.clientY);
      }}
      tabIndex={0}
    >
      <ImagePreloader
        filesToLoad={roomImagesToPreload}
        setIsLoaded={setAreImagesPreloaded}
      />
      <StyledRoomContainer
        isDragging={isDragging}
        onMouseDown={(e) => {
          if (shouldDrag(e) && !isDragging) {
            startDrag(e.clientX, e.clientY);
          }
        }}
        onTouchStart={(e) => {
          if (shouldDrag(e)) {
            if (e.touches.length > 1) return;
            const touchLocation = e.touches[0];
            startDrag(touchLocation.clientX, touchLocation.clientY);
          }
        }}
        onMouseMove={(e) => {
          if (!isDragging) return;
          if (shouldDrag(e) && isDragging) {
            moveDrag(e.clientX, e.clientY);
          }
        }}
        onTouchMove={(e) => {
          if (shouldDrag(e)) {
            if (e.touches.length > 1) return;
            //update pan
            if (e.touches.length === 1) {
              const touchLocation: any = e.touches[0];
              moveDrag(touchLocation.clientX, touchLocation.clientY);
            }
          }
        }}
        onMouseUp={() => {
          endDrag();
        }}
        onTouchEnd={(e) => {
          if (shouldDrag(e)) {
            if (e.touches.length > 1) return;

            endDrag();
          }
        }}
        className={"text-clickable"}
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 3,
        }}
      >
        {!isAVLoaded || !areImagesPreloaded ? (
          <LoadingPage noText />
        ) : (
          <>
            <AnimatePresence>
              <SpreadTextOverlay
                key={"spread-text-overlay"}
                userId={user?.uuid ? user.uuid : ""}
              />
              {(isOnMobile || (!isOnMobile && !isInDrawState)) && (
                <Perspective
                  key="shuffle"
                  perspective={PERSPECTIVE_SHUFFLE}
                  angle={ANGLE_SHUFFLE}
                  useDefaultZoom={true}
                >
                  <Shuffle />{" "}
                </Perspective>
              )}
              <>
                <Spread />
                <Hand key="hand" />
              </>
            </AnimatePresence>
            <ActionPortalTarget />

            <RoomMenus />
            <BottomBar />
            <Cursors />
          </>
        )}

        <AV roomId={id} isAVLoaded={isAVLoaded} setIsAVLoaded={setIsAVLoaded} />
      </StyledRoomContainer>
    </ThemedApp>
  );
});

const StyledRoomContainer = React.memo(
  styled(motion.div, {
    position: "fixed",
    inset: 0,
    overflow: "hidden",
    // cursor: "grab",
    variants: {
      isDragging: {
        true: {
          // cursor: "grabbing",
        },
      },
    },
  })
);
